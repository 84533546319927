import React, { useContext } from "react"
import PropTypes from "prop-types"

import { LangContext } from "../../context/langContext"

import { CardNavBottom } from "../card/card"

export const NavBottom = ({
  prevTitle,
  prevImg,
  prevLink,
  nextTitle,
  nextImg,
  nextLink,
  className,
}) => {
  const { lang } = useContext(LangContext)
  return (
    <div className={`nav-bottom ${className}`}>
      <div className="row row-0">
        <div className="col">
          <CardNavBottom
            variant="overlay"
            imgOverlay="50"
            label={lang === "EN" ? "Previous" : "Sebelumnya"}
            title={prevTitle}
            img={prevImg}
            imgHeight="h-150px h-md-200px d-flex"
            link={prevLink}
            className="nav-bottom-item prev"
          />
        </div>
        <div className="col">
          <CardNavBottom
            variant="overlay"
            imgOverlay="50"
            label={lang === "EN" ? "Next" : "Selanjutnya"}
            title={nextTitle}
            img={nextImg}
            imgHeight="h-150px h-md-200px d-flex"
            link={nextLink}
            className="nav-bottom-item next"
          />
        </div>
      </div>
    </div>
  )
}

NavBottom.propTypes = {
  prevTitle: PropTypes.string, // Set previous title
  prevImg: PropTypes.any, // Set previous image
  prevLink: PropTypes.string, // Set previous link / href
  nextTitle: PropTypes.string, // Set next title
  nextImg: PropTypes.any, // Set next image
  nextLink: PropTypes.string, // Set next link / href
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

NavBottom.defaultProps = {
  prevLink: "",
  nextLink: "",
  className: "",
}
